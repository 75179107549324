import { defineChain } from 'viem';

export const polyHedraTestnet = defineChain({
	id: 18880,
	name: 'EXPchain',
	nativeCurrency: { name: 'tZKJ', symbol: 'tZKJ', decimals: 18 },
	rpcUrls: {
		default: {
			http: ['https://rpc1-testnet.expchain.ai'],
		},
	},
	blockExplorers: {
		default: {
			name: 'EXPchain Testnet Explorer',
			url: 'https://blockscout-testnet.expchain.ai/',
		},
	},
	testnet: true,
});
