import { Badge } from '@/components/ui/badge';
import { Button } from '@/components/ui/button';
import { Card, CardContent, CardHeader, CardTitle } from '@/components/ui/card';
import { Skeleton } from '@/components/ui/skeleton';
import useCampaignRewards from '@/features/campaigns/hooks/report/useCampaignRewards';
import { CampaignRewardCategory, rewardTypeMap } from '@/features/campaigns/types';
import { RewardCard } from '../rewardCard/RewardCard';
import TooltipWrapper from '@/components/element/tooltips/TooltipWrapper';
import { useMemo } from 'react';
import dayjs from 'dayjs';
import { getScanLink } from '@/utils/blockChain';
import { useCampaignReport } from '@/features/campaigns/hooks/report/useCampaignReport';

const RewardDetails = () => {
	const {
		rewardDetails,
		isLoading,
		currentReward,
		handleNext,
		handlePrev,
		currentIndex,
		campaignStatus,
		campaignClaimDate,
		isWithdrawn,
		refundTxn,
	} = useCampaignRewards();
	const { campaign } = useCampaignReport();

	const tooltipText = useMemo(() => {
		const now = dayjs();
		const claimDate = dayjs(new Date(campaignClaimDate));

		if (claimDate.isAfter(now)) {
			const remainingDays = claimDate.diff(now, 'days');

			if (remainingDays > 0) {
				return `You can withdraw pending tokens in ${remainingDays} days, i.e. on ${claimDate.format('MMMM DD, YYYY')}.`;
			} else {
				return `You can withdraw pending tokens after 30 days of campaign completion, i.e. ${claimDate.format('MMMM DD, YYYY')}.`;
			}
		} else {
			// if (!isWithdrawn) {
			// 	return 'The claim period has passed. You can now withdraw your tokens.';
			// }
			return '';
		}
	}, [campaignClaimDate]);

	if (!rewardDetails || rewardDetails.length === 0) {
		return (
			<>
				{campaign?.speedRunConfig?.isEnrolled &&
					campaign?.speedRunConfig?.transaction?.txHash && (
						<SpeedrunConfig config={campaign?.speedRunConfig} />
					)}
				<Card className="">
					<CardHeader className="border-b py-4">
						<div className="flex items-center justify-between">
							<CardTitle className="text-base leading-5 font-semibold text-slate-700">
								Reward Details
							</CardTitle>
							<Badge
								variant="secondary"
								className="py-1 text-slate-700"
							>
								0 Reward(s)
							</Badge>
						</div>
					</CardHeader>
					{isLoading ? (
						<div className="space-y-4 mt-5">
							{[...Array(6).keys()].map((_, index) => (
								<div key={index} className="px-4 ">
									<Skeleton className="h-7 w-full" />
								</div>
							))}
						</div>
					) : (
						<CardContent className="flex items-center justify-center h-full px-10 text-center">
							<div className="text-sm text-muted-foreground mb-14">
								No rewards have been set up for this quest.
							</div>
						</CardContent>
					)}
				</Card>
			</>
		);
	}

	return (
		<div>
			{campaign?.speedRunConfig?.isEnrolled &&
				campaign?.speedRunConfig?.transaction?.txHash && (
					<SpeedrunConfig config={campaign?.speedRunConfig} />
				)}
			<Card className=" flex flex-col">
				<CardHeader className="border-b p-4">
					<div className="flex items-center justify-between">
						<CardTitle className="text-base leading-5 font-semibold text-slate-700">
							{rewardTypeMap[currentReward.rewardType]}
							{currentReward.rewardType ===
								CampaignRewardCategory.Token && tooltipText ? (
								<TooltipWrapper
									tooltip={tooltipText}
									align="end"
									contentClassName="max-w-[22rem]"
								>
									<i className="bi-info-circle ms-1 text-sm" />
								</TooltipWrapper>
							) : isWithdrawn ? (
								<TooltipWrapper
									tooltip="Tokens have been withdrawn. Check the transaction details."
									align="end"
								>
									<a
										className="text-primary-500"
										onClick={() => {
											window.open(
												getScanLink(
													refundTxn?.hash,
													refundTxn?.chain,
													'tx',
												),
												'_blank',
											);
										}}
									>
										<i className="bi-box-arrow-up-right ms-2 text-sm" />
									</a>
								</TooltipWrapper>
							) : null}
						</CardTitle>
						<div className="flex items-center gap-1">
							{rewardDetails.length > 1 && (
								<div className="space-x-1">
									<Button
										size="icon"
										variant="outline"
										className="size-[26px]"
										onClick={handlePrev}
										disabled={currentIndex === 0}
									>
										<i className="bi-chevron-left" />
									</Button>
									<Button
										size="icon"
										variant="outline"
										className="size-[26px]"
										onClick={handleNext}
										disabled={
											currentIndex === rewardDetails.length - 1
										}
									>
										<i className="bi-chevron-right" />
									</Button>
								</div>
							)}
							<Badge
								variant="secondary"
								className="py-1 text-slate-700"
							>
								{rewardDetails.length} Reward(s)
							</Badge>
						</div>
					</div>
				</CardHeader>
				<CardContent className="relative shadow-none border-none p-4 h-full">
					{currentReward && (
						<RewardCard
							reward={currentReward}
							campaignStatus={campaignStatus}
							isWithdrawn={!!isWithdrawn}
							campaignClaimDate={campaignClaimDate}
						/>
					)}
				</CardContent>
			</Card>
		</div>
	);
};

export default RewardDetails;

const SpeedrunConfig = ({ config }) => {
	return (
		<Card className="text-sm p-3 mb-4 bg-slate-50">
			You’ve successfully applied for the Speedrun Booster! Your campaign will
			be featured in our upcoming Speedrun to help you attract more engaged and
			valuable users.
			<span
				onClick={() => {
					window?.Intercom?.('showNewMessage');
				}}
				className="ms-1 underline cursor-pointer text-primary-500"
			>
				Curious about how Speedrun works?
			</span>
		</Card>
	);
};
