import { defineChain } from 'viem';

export const uniChainMainnet = defineChain({
	id: 130,
	name: 'UniChain',
	nativeCurrency: { name: 'ETH', symbol: 'ETH', decimals: 18 },
	rpcUrls: {
		default: {
			http: [
				'https://misty-responsive-meme.unichain-mainnet.quiknode.pro/980258d6b85aa6b12c1a20bc64aeb99d454dad6a',
			],
		},
	},
	blockExplorers: {
		default: {
			name: 'Unichain Mainnet Explorer',
			url: 'https://uniscan.xyz/',
		},
	},
	testnet: false,
});
