import { useAuth } from '@/hooks/useAuth';
import { useState } from 'react';
import { useConfirmEmail } from '../../hooks/useConfirmEmail';
import { useGetEnterprise } from '../../hooks/useGetEnterprise';

const BannerNotification = () => {
	const { user } = useAuth();
	const { resendEmail } = useConfirmEmail();
	const [tempShow, setTempShow] = useState(true);
	const { enterprise } = useGetEnterprise();

	// return <></>;
	if (enterprise?.isDeleted) {
		return (
			<div className="flex w-full items-center justify-center bg-red-200 text-amber-800 text-primary text-xs py-2  ">
				<i className="bi-info-circle text-amber-80 me-1"></i>
				Your Enterprise was archived due to inactivity and potential suspicious actions. Kindly contact the  
				<span
					onClick={() => window.Intercom('showNewMessage')}
					className="hover:underline px-1 cursor-pointer font-semibold text-primary-500 underline hover:opacity-80"
				>
					support team
				</span>
				for more information.
			</div>
		);
	}
	else if (!user.isEmailVerified && tempShow) {
		return (
			<div className="flex w-full items-center justify-center bg-warning/35 text-amber-800 text-primary text-xs py-2  ">
				<i className="bi-info-circle text-amber-80 me-1"></i>
				Verify your email before launching your first campaign! Check your
				email for the confirmation link.
				<span
					onClick={resendEmail}
					className="hover:underline px-1 cursor-pointer font-semibold text-primary-500 underline hover:opacity-80"
				>
					Resend email
				</span>
				<div
					className="absolute end-0 pe-4"
					onClick={() => setTempShow(false)}
				>
					<i className="bi-x text-xl cursor-pointer"></i>
				</div>
			</div>
		);
	}
	return <></>;
};

export default BannerNotification;
