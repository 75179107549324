import { SPEEDRUN_USER_ADDRESS } from '@/config';
import erc20Abi from '@/config/abi/ERC20ABI.json';
import { ERC20RewardABI } from '@/config/abi/ERC20RewardABI';
import { useCreateTxn } from '@/hooks/useCreateTxn';
import { ContractType, useGetContract } from '@/hooks/useGetContract';
import { useReadContracts } from '@/hooks/useReadContracts';
import analytics from '@/lib/analytics';
import { queryClient } from '@/lib/react-query';
import { TrackingEvents } from '@/types/tracking.type';
import { getScanLink } from '@/utils/blockChain';
import { handleErrorMessage } from '@/utils/notifications';
import { BigNumber, ethers } from 'ethers';
import { useEffect, useMemo, useState } from 'react';
import { toast } from 'sonner';
import { useAccount } from 'wagmi';
import {
	checkIfTokenRewardTxnAlreadyDone,
	getTokenRewardTransferSignature,
	updateCampaignAdminAddress,
	updateSpeedrunTransferTxn,
	updateTokenRewardApprovalTxn,
	updateTokenRewardTransferTxn,
} from '../../services/campaigns.service';
import { ICampaignReward, ISpeedRunConfig } from '../../types';
import { createErrorMessage, useDepositTokenStatus } from './useDepositTokenStatus';

export const useDepositToken = ({
	campaignId,
	tokenReward,
	speedRunConfig,
}: {
	campaignId: string;
	tokenReward: ICampaignReward;
	speedRunConfig: ISpeedRunConfig;
}) => {
	const { contract } = useGetContract(
		ContractType.IntractCampaignRewardToken,
		tokenReward?.tokenReward?.chain,
		tokenReward?.tokenReward?.namespaceTag,
	);
	const [status, setStatus] = useState('Connect Wallet');
	const [isLoading, setIsLoading] = useState(false);
	const chainId = Number(tokenReward?.tokenReward?.chainId);
	const { address, chainId: selectedChainId, isConnected } = useAccount();
	const [userBalance, setUserBalance] = useState('');
	const { readContract } = useReadContracts();
	const { startTxn } = useCreateTxn();
	const [isSpeedrunDone, setIsSpeedrunDone] = useState(false);
	const [walletCheckDone, setWalletCheckDone] = useState(false);

	const { steps, resetSteps, updateSteps } = useDepositTokenStatus({
		isLoading,
	});

	const requiredAmount = useMemo(() => {
		if (!tokenReward) return '';
		try {
			let tokenRewardPool = (
				tokenReward?.tiersRewardPool && tokenReward?.tiers?.length > 0
					? Number(tokenReward?.tiersRewardPool)
					: Number(tokenReward?.tokenReward?.tokenAmountPerUser) *
						tokenReward?.numRewards
			)?.toFixed(tokenReward?.tokenReward?.tokenDecimals);

			if (speedRunConfig?.isEnrolled) {
				const rewardPool = Number(speedRunConfig?.tokenAmount);
				tokenRewardPool = (+tokenRewardPool + rewardPool)?.toString();
			}

			if (
				!tokenRewardPool ||
				isNaN(Number(tokenRewardPool)) ||
				Number(tokenRewardPool) <= 0
			) {
				console.log('Invalid token reward pool value:', tokenRewardPool);
				return ethers.BigNumber.from(0);
			}

			const sanitizedAmount = Number(tokenRewardPool)
				.toFixed(tokenReward?.tokenReward?.tokenDecimals)
				.toString();

			return ethers.utils.parseUnits(
				sanitizedAmount,
				tokenReward?.tokenReward?.tokenDecimals,
			);
		} catch (err) {
			console.log('Error calculating required amount:', err);
			toast.error('Failed to calculate required amount');
			return ethers.BigNumber.from(0);
		}
	}, [tokenReward, speedRunConfig]);

	useEffect(() => {
		setWalletCheckDone(true);

		if (!isConnected || !address) {
			setUserBalance('');
			setStatus('Connect Wallet');
		}
	}, [isConnected, address, selectedChainId, chainId]);

	const startProcess = async () => {
		try {
			setIsLoading(true);
			resetSteps();

			if (!isConnected || !address) {
				toast.error('Please connect your wallet first');
				updateSteps({
					step: 'requiredAmount',
					isLoading: false,
					isSuccess: false,
					isError: true,
					error: {
						message:
							'Wallet not connected. Please connect your wallet first.',
						code: 'WALLET_NOT_CONNECTED',
					},
				});
				throw new Error(
					'Wallet not connected. Please connect your wallet first.',
				);
			}

			if (chainId !== selectedChainId) {
				toast.error('Please switch to the correct network');
				updateSteps({
					step: 'requiredAmount',
					isLoading: false,
					isSuccess: false,
					isError: true,
					error: {
						message: `Wrong network. Please switch to chain ID ${chainId}.`,
						code: 'WRONG_NETWORK',
					},
				});
				throw new Error(
					`Wrong network. Please switch to chain ID ${chainId}.`,
				);
			}

			console.log('1/checkRequiredBalance');
			await checkRequiredBalance();
			console.log('2/startApprovalTransaction');
			await startApprovalTransaction();
			if (speedRunConfig?.isEnrolled) {
				console.log(`3/startSpeedRunTransaction`);
				await startSpeedRunTransaction();
			}
			console.log('4/startTransferTransaction');
			await startTransferTransaction();
			return true;
		} catch (err) {
			handleErrorMessage(err);
			console.log(err);
			return false;
		} finally {
			setIsLoading(false);
		}
	};

	const checkRequiredBalance = async () => {
		try {
			console.log('checkRequiredBalance');
			updateSteps({
				step: 'requiredAmount',
				isLoading: true,
				isSuccess: false,
				isError: false,
			});

			if (!isConnected || !address) {
				const message = 'Please connect your wallet to check balance';
				updateSteps({
					step: 'requiredAmount',
					isLoading: false,
					isSuccess: false,
					isError: true,
					error: {
						message,
						code: 'WALLET_NOT_CONNECTED',
					},
				});
				throw new Error(message);
			}

			let balance;
			try {
				balance = await readContract({
					chainId,
					contractAddress: tokenReward?.tokenReward?.tokenAddress,
					ABI: erc20Abi,
					fnName: 'balanceOf',
					args: [address],
				});

				console.log('balance', balance, typeof balance);

				// Handle various formats of balance (BigInt, BigNumber, etc.)
				if (balance === null || balance === undefined) {
					throw new Error('Could not retrieve your token balance');
				}

				// Convert BigInt to string if needed
				if (typeof balance === 'bigint') {
					console.log(
						'Converting BigInt balance to string:',
						balance.toString(),
					);
					balance = ethers.BigNumber.from(balance.toString());
				}
				// If it's already a BigNumber, no action needed
				else if (!ethers.BigNumber.isBigNumber(balance)) {
					// For any other type, convert to BigNumber
					console.log('Converting to BigNumber:', balance);
					try {
						balance = ethers.BigNumber.from(balance);
					} catch (conversionErr) {
						console.log('BigNumber conversion error:', conversionErr);
						throw new Error(`Invalid balance format: ${typeof balance}`);
					}
				}

				console.log('Final balance as BigNumber:', balance.toString());
			} catch (balanceErr) {
				console.log('Balance fetch error details:', balanceErr);

				const message =
					balanceErr.message && balanceErr.message.includes('network')
						? balanceErr.message
						: 'Failed to fetch balance. Please verify your wallet is connected to the correct network.';

				updateSteps({
					step: 'requiredAmount',
					isLoading: false,
					isSuccess: false,
					isError: true,
					error: {
						message,
						code: 'BALANCE_FETCH_FAILED',
					},
				});
				throw new Error(message);
			}

			const tokenSymbol = tokenReward?.tokenReward?.tokenSymbol || 'Token';
			const formattedBalance = ethers.utils.formatUnits(
				balance as BigNumber,
				tokenReward?.tokenReward?.tokenDecimals,
			);
			setUserBalance(
				(
					+balance?.toString() /
					10 ** tokenReward?.tokenReward?.tokenDecimals
				)?.toString(),
			);

			if (!requiredAmount || ethers.BigNumber.from(requiredAmount).isZero()) {
				throw new Error(
					'Required amount calculation failed. Please try again.',
				);
			}

			try {
				if (!ethers.BigNumber.from(balance).gte(requiredAmount)) {
					const requiredFormatted = ethers.utils.formatUnits(
						requiredAmount,
						tokenReward?.tokenReward?.tokenDecimals,
					);

					const shortfallBN = ethers.BigNumber.from(requiredAmount).sub(
						ethers.BigNumber.from(balance),
					);
					const shortfallFormatted = ethers.utils.formatUnits(
						shortfallBN,
						tokenReward?.tokenReward?.tokenDecimals,
					);

					const errorMessage = `Insufficient ${tokenSymbol} Balance. You have: ${formattedBalance} ${tokenSymbol} Required: ${requiredFormatted} ${tokenSymbol}\nShortfall: ${shortfallFormatted} ${tokenSymbol}`;

					updateSteps({
						step: 'requiredAmount',
						isLoading: false,
						isSuccess: false,
						isError: true,
						error: {
							message: errorMessage,
							code: 'INSUFFICIENT_BALANCE',
						},
					});
					throw new Error(errorMessage);
				}
			} catch (compareErr) {
				if (
					compareErr.message &&
					compareErr.message.includes('Insufficient')
				) {
					// This is an expected error for insufficient balance, just rethrow it
					throw compareErr;
				}
				const errorMessage =
					'Error comparing balance values. Please try again.';
				updateSteps({
					step: 'requiredAmount',
					isLoading: false,
					isSuccess: false,
					isError: true,
					error: {
						message: errorMessage,
						code: 'BALANCE_COMPARISON_ERROR',
					},
				});
				throw new Error(errorMessage);
			}
			updateSteps({
				step: 'requiredAmount',
				isLoading: false,
				isSuccess: true,
				isError: false,
			});
		} catch (err) {
			const errorMessage =
				err instanceof Error
					? err.message
					: `Failed to check ${tokenReward?.tokenReward?.tokenSymbol || 'token'} balance`;

			if (!steps.requiredAmount.error) {
				updateSteps({
					step: 'requiredAmount',
					isLoading: false,
					isSuccess: false,
					isError: true,
					error: {
						message: errorMessage,
						code: 'BALANCE_CHECK_FAILED',
					},
				});
			}
			console.log('error in fetching token balance');
			throw err;
		}
	};

	const startApprovalTransaction = async () => {
		try {
			if (!isConnected || !address) {
				throw new Error(
					'Wallet not connected. Please connect your wallet first.',
				);
			}

			setStatus('(1/2) Approve Token Transaction');
			updateSteps({
				step: 'approveToken',
				isLoading: true,
				isSuccess: false,
				isError: false,
			});
			const receiver = contract.address;

			if (!receiver) {
				throw new Error('Contract address not found. Please try again.');
			}

			const args = [receiver, requiredAmount];

			try {
				const approvedAmount = await readContract({
					chainId,
					contractAddress: tokenReward?.tokenReward?.tokenAddress,
					ABI: erc20Abi,
					fnName: 'allowance',
					args: [address, receiver],
				});

				if (ethers.BigNumber.from(approvedAmount).gte(requiredAmount)) {
					analytics.track(TrackingEvents.LaunchQuestTokenApproved, {
						preApproved: true,
						chainId,
					});

					const approvedAmount_ = (
						+approvedAmount?.toString() /
						10 ** tokenReward?.tokenReward?.tokenDecimals
					)?.toFixed(4);

					// Check for a previous approval transaction hash from multiple potential sources
					let prevTxHash =
						tokenReward?.tokenReward?.approvalTransaction?.txHash;

					if (prevTxHash) {
						updateSteps({
							step: 'approveToken',
							isLoading: false,
							isSuccess: true,
							isError: false,
							txHash: prevTxHash,
							scanLink: getScanLink(
								prevTxHash,
								tokenReward?.tokenReward?.chain,
								'tx',
							),
							successMessage: `Token approval already granted for ${approvedAmount_}$${tokenReward?.tokenReward?.tokenSymbol}`,
						});
					} else {
						try {
							const res =
								await checkIfTokenRewardTxnAlreadyDone(campaignId);
							if (res?.data?.approvalTxn?.txHash) {
								prevTxHash = res.data.approvalTxn.txHash;
								updateSteps({
									step: 'approveToken',
									isLoading: false,
									isSuccess: true,
									isError: false,
									txHash: prevTxHash,
									scanLink: getScanLink(
										prevTxHash,
										tokenReward?.tokenReward?.chain,
										'tx',
									),
									successMessage: `Token approval already granted for ${approvedAmount_}$${tokenReward?.tokenReward?.tokenSymbol}`,
								});
							} else {
								updateSteps({
									step: 'approveToken',
									isLoading: false,
									isSuccess: true,
									isError: false,
									successMessage: `Token approval already granted for ${approvedAmount_}$${tokenReward?.tokenReward?.tokenSymbol}`,
								});
							}
						} catch (err) {
							updateSteps({
								step: 'approveToken',
								isLoading: false,
								isSuccess: true,
								isError: false,
								successMessage: `Token approval already granted for ${approvedAmount_}$${tokenReward?.tokenReward?.tokenSymbol}`,
							});
						}
					}

					await updateCampaignAdminAddress(campaignId, {
						adminAddress: address,
					});
					return;
				}
			} catch (err) {
				console.log(err);
				console.log('error in fetching token allowance');
				// Continue with approval if allowance check fails
			}

			const txn = await startTxn({
				chainId,
				contractAddress: tokenReward?.tokenReward?.tokenAddress,
				ABI: erc20Abi,
				fnName: 'approve',
				args: [receiver, requiredAmount?.toString()],
			});

			if (!txn || !txn.transactionHash) {
				console.log(
					'Token Approval Transaction failed, please reach out to support',
					txn,
				);
				throw new Error(
					'Token Approval Transaction failed, please reach out to support',
				);
			}
			updateSteps({
				step: 'approveToken',
				isLoading: false,
				isSuccess: true,
				isError: false,
				txHash: txn?.transactionHash,
				scanLink: getScanLink(
					txn?.transactionHash,
					tokenReward?.tokenReward?.chain,
					'tx',
				),
			});
			analytics.track(TrackingEvents.LaunchQuestTokenApproved, {
				preApproved: false,
				chainId,
			});

			await updateTokenRewardApprovalTxn(campaignId, {
				adminAddress: txn.account,
				rewardId: tokenReward._id,
				txHash: txn.transactionHash,
				status: 'completed',
				chainId: chainId,
				receipt: txn.receipt,
				chain: tokenReward?.tokenReward?.chain,
				namespaceTag: tokenReward?.tokenReward?.namespaceTag,
			});
			return;
		} catch (err) {
			const tokenSymbol = tokenReward?.tokenReward?.tokenSymbol || 'Token';
			const errorMessage =
				err instanceof Error
					? err.message
					: createErrorMessage.tokenApprovalFailed(tokenSymbol);

			updateSteps({
				step: 'approveToken',
				isLoading: false,
				isSuccess: false,
				isError: true,
				error: {
					message: errorMessage,
					code: 'TOKEN_APPROVAL_FAILED',
				},
			});
			throw err;
		}
	};

	const startTransferTransaction = async () => {
		const tokenSymbol = tokenReward?.tokenReward?.tokenSymbol || 'Token';
		const rewardPool = (
			tokenReward?.tiersRewardPool
				? +tokenReward?.tiersRewardPool
				: +tokenReward?.tokenReward?.tokenAmountPerUser *
					tokenReward?.numRewards
		)?.toString();
		try {
			if (!isConnected || !address) {
				throw new Error(
					'Wallet not connected. Please connect your wallet first.',
				);
			}

			console.log('startTransferTransaction');

			updateSteps({
				step: 'transferToken',
				isLoading: true,
				isSuccess: false,
				isError: false,
			});
			const res = await getTokenRewardTransferSignature(campaignId);
			const signatureData = res.txnData;
			setStatus('(2/2) Transfer Token Transaction');

			const agrs = formatArgs(signatureData.functionParams);

			console.log('quest-amount', agrs);

			const txn = await startTxn({
				chainId,
				contractAddress: contract.address,
				ABI: ERC20RewardABI,
				fnName: signatureData.functionName,
				args: agrs,
			});
			if (!txn || !txn?.transactionHash) {
				throw new Error(
					createErrorMessage.transferFailed(tokenSymbol, rewardPool),
				);
			}
			console.log('txn', txn);
			const receipt = txn?.receipt;
			if (!receipt?.decodedLogs) {
				throw new Error(
					`Verification failed for ${rewardPool?.toString()} ${tokenSymbol} transfer`,
				);
			}
			await updateTokenRewardTransferTxn(campaignId, {
				txHash: txn.transactionHash,
				status: 'completed',
				chainId: chainId,
				receipt: txn.receipt,
				chain: tokenReward?.tokenReward?.chain,
				namespaceTag: tokenReward?.tokenReward?.namespaceTag,
			});
			analytics.track(TrackingEvents.LaunchQuestTokenTransferred, {
				chainId,
			});
			updateSteps({
				step: 'transferToken',
				isLoading: false,
				isSuccess: true,
				isError: false,
				txHash: txn?.transactionHash,
				scanLink: getScanLink(
					txn?.transactionHash,
					tokenReward?.tokenReward?.chain,
					'tx',
				),
			});
			setStatus('Transaction Completed');
		} catch (err) {
			const tokenSymbol = tokenReward?.tokenReward?.tokenSymbol || 'Token';

			const errorMessage =
				err instanceof Error
					? err.message
					: createErrorMessage.transferFailed(tokenSymbol, rewardPool);

			updateSteps({
				step: 'transferToken',
				isLoading: false,
				isSuccess: false,
				isError: true,
				error: {
					message: errorMessage,
					code: 'TOKEN_TRANSFER_FAILED',
				},
			});
			throw err;
		}
	};

	const startSpeedRunTransaction = async () => {
		try {
			if (!speedRunConfig?.isEnrolled) return;

			// Verify wallet is connected
			if (!isConnected || !address) {
				throw new Error(
					'Wallet not connected. Please connect your wallet first.',
				);
			}

			if (speedRunConfig?.transaction?.txHash) {
				updateSteps({
					step: 'transferSpeedRun',
					isLoading: false,
					isSuccess: true,
					isError: false,
					txHash: speedRunConfig?.transaction?.txHash,
					scanLink: getScanLink(
						speedRunConfig?.transaction?.txHash,
						tokenReward?.tokenReward?.chain,
						'tx',
					),
				});
				return;
			}
			if (isSpeedrunDone) {
				updateSteps({
					step: 'transferSpeedRun',
					isLoading: false,
					isSuccess: true,
					isError: false,
				});
			}
			updateSteps({
				step: 'transferSpeedRun',
				isLoading: true,
				isSuccess: false,
				isError: false,
			});

			const tokenAmount = speedRunConfig?.tokenAmount;
			const tokenSymbol = tokenReward?.tokenReward?.tokenSymbol || 'Token';
			const formattedAmount = ethers.utils.parseUnits(
				tokenAmount,
				tokenReward?.tokenReward?.tokenDecimals,
			);
			console.log('speed-run-amount', formattedAmount);

			const speedrunAddress = SPEEDRUN_USER_ADDRESS;
			const txn = await startTxn({
				chainId,
				contractAddress: tokenReward?.tokenReward?.tokenAddress,
				ABI: erc20Abi,
				fnName: 'transfer',
				args: [speedrunAddress, formattedAmount],
			});
			console.log('tx', txn);

			if (!txn || !txn?.transactionHash) {
				throw new Error(
					createErrorMessage.speedrunTransferFailed(
						tokenSymbol,
						tokenAmount,
					),
				);
			}
			await updateSpeedrunTransferTxn(campaignId, {
				txHash: txn.transactionHash,
				status: 'completed',
				chainId: chainId,
				receipt: txn.receipt,
				chain: tokenReward?.tokenReward?.chain,
				namespaceTag: tokenReward?.tokenReward?.namespaceTag,
			});
			analytics.track(TrackingEvents.LaunchQuestTokenTransferred, {
				chainId,
			});
			updateSteps({
				step: 'transferSpeedRun',
				isLoading: false,
				isSuccess: true,
				isError: false,
				txHash: txn?.transactionHash,
				scanLink: getScanLink(
					txn?.transactionHash,
					tokenReward?.tokenReward?.chain,
					'tx',
				),
			});
			setStatus('Speedrun Transaction Completed');

			await queryClient.invalidateQueries({
				queryKey: ['campaign', campaignId],
			});
			setIsSpeedrunDone(true);
		} catch (err) {
			const tokenSymbol = tokenReward?.tokenReward?.tokenSymbol || 'Token';

			const errorMessage =
				err instanceof Error
					? err.message
					: createErrorMessage.speedrunTransferFailed(
							tokenSymbol,
							speedRunConfig?.tokenAmount,
						);

			updateSteps({
				step: 'transferSpeedRun',
				isLoading: false,
				isSuccess: false,
				isError: true,
				error: {
					message: errorMessage,
					code: 'SPEEDRUN_TRANSFER_FAILED',
				},
			});
			throw err;
		}
	};

	return {
		startProcess,
		startApprovalTransaction,
		startTransferTransaction,
		isLoading,
		isConnected: !!address && isConnected,
		isChainSelected: chainId === selectedChainId,
		status,
		steps,
		userBalance,
		requiredAmount,
		walletCheckDone,
		resetSteps,
	};
};

function formatArgs(args: any) {
	return args.map((arg: any) => {
		if (Array.isArray(arg)) {
			return arg.map((item) => ({
				rewardId: ethers.BigNumber.from(item.rewardId.hex),
				tokenAddress: item.tokenAddress,
				numRewards: ethers.BigNumber.from(item?.numRewards.hex),
			}));
		} else if (arg.type && arg.type === 'BigNumber') {
			return ethers.BigNumber.from(arg.hex);
		} else {
			return arg;
		}
	});
}
