import { defineChain } from 'viem';

export const storyProtocolMainnet = defineChain({
	id: 1514,
	name: 'Story Protocol',
	nativeCurrency: { name: 'IP', symbol: 'IP', decimals: 18 },
	rpcUrls: {
		default: {
			http: ['https://mainnet.storyrpc.io'],
		},
	},
	blockExplorers: {
		default: {
			name: 'Story Protocol Mainnet Explorer',
			url: 'https://www.storyscan.xyz/',
		},
	},
	testnet: false,
});
