import Spinner from '@/components/element/loading/Spinner';
import { Button } from '@/components/ui/button';
import { Dialog, DialogContent } from '@/components/ui/dialog';
import {
	CampaignLaunchStep,
	useCampaignLaunch,
} from '@/features/campaigns/hooks/launch/useCampaignLaunch';
import { useCampaignReport } from '@/features/campaigns/hooks/report/useCampaignReport';
import { ICampaignReward } from '@/features/campaigns/types';
import analytics from '@/lib/analytics';
import { TrackingEvents } from '@/types/tracking.type';
import { useEffect } from 'react';
import IntroducingSpeedrun from '../../boosters/speedrun/IntroducingSpeedrun';
import DepositTokenDialog from './DepositTokenDialog';
import DepositTokenDialogWithSpeedrun from './DepositTokenDialogWithSpeedrun';
import { LaunchConfirmation } from './LaunchConfirmation';
import TransactionStatus from './TransactionStatus';

const CampaignLaunchDialog = ({
	open,
	setOpen,
	campaignId,
	startImmediately,
	tokenReward,
	showRewardInfo,
}: {
	open: boolean;
	setOpen: (value: boolean) => void;
	campaignId: string;
	startImmediately: boolean;
	tokenReward: ICampaignReward;
	showRewardInfo?: boolean;
}) => {
	const { campaign } = useCampaignReport();
	const {
		step,
		handleNext,
		isLoading,
		btnLabel,
		isCampaignLoading,
		speedRunConfig,
		setSpeedRunConfig,
		steps,
		userBalance,
		handleBack,
		handleButtonClick,
		needWalletConnect,
		canSwitchWallet,
	} = useCampaignLaunch({
		startImmediately,
		campaignId,
		setOpen,
	});
	const {
		botFilteringMethods,
		setBotFilteringMethods,
		hasPoHTask,
		hasCaptchaTask,
	} = useCampaignReport();

	useEffect(() => {
		if (open) {
			analytics.track(TrackingEvents.LaunchQuestViewed, {
				campaignId,
				isTokenReward: !!tokenReward,
			});
		}
	}, [open]);

	return (
		<Dialog open={open} onOpenChange={setOpen}>
			<DialogContent className="sm:max-w-[570px] p-7">
				{step === CampaignLaunchStep.Confirmation && (
					<LaunchConfirmation
						showRewardInfo={showRewardInfo}
						botFilteringMethods={botFilteringMethods}
						setBotFilteringMethods={setBotFilteringMethods}
						hasCaptchaTask={hasCaptchaTask}
						hasPoHTask={hasPoHTask}
					/>
				)}
				{step === CampaignLaunchStep.SpeedRun && tokenReward && (
					<IntroducingSpeedrun />
				)}
				{step === CampaignLaunchStep.TokenWithSpeedrun && tokenReward && (
					<DepositTokenDialogWithSpeedrun
						tokenReward={tokenReward}
						speedRunConfig={speedRunConfig}
						setSpeedRunConfig={setSpeedRunConfig}
					/>
				)}
				{step === CampaignLaunchStep.Token && tokenReward && (
					<DepositTokenDialog tokenReward={tokenReward} />
				)}
				{step === CampaignLaunchStep.TransactionStatus && tokenReward && (
					<TransactionStatus
						tokenReward={tokenReward}
						steps={steps}
						userBalance={userBalance}
						isSpeedRunEnabled={campaign?.speedRunConfig?.isEnrolled}
					/>
				)}
				<div className="mt-2 flex items-center justify-center">
					{/* Skip button for SpeedRun step */}
					{step === CampaignLaunchStep.SpeedRun && (
						<Button
							onClick={() => handleNext(false)}
							disabled={isLoading || isCampaignLoading}
							size="lg"
							className="me-2"
							variant="outline"
						>
							<span>Skip</span>
						</Button>
					)}

					{/* Back button for Transaction step */}
					{step === CampaignLaunchStep.TransactionStatus &&
						!isLoading &&
						!campaign?.speedRunConfig?.transaction?.txHash && (
							<Button
								onClick={handleBack}
								size="lg"
								className="me-2"
								variant="outline"
							>
								<span>Back</span>
							</Button>
						)}

					{/* Main action button */}
					<Button
						onClick={handleButtonClick}
						disabled={isLoading || isCampaignLoading}
						size="lg"
						className={`w-full ${
							step === CampaignLaunchStep.TransactionStatus &&
							!needWalletConnect &&
							!canSwitchWallet &&
							!Object.values(steps)
								?.map((i) => i.isError)
								?.some((i) => i)
								? 'shimmer'
								: ''
						}`}
					>
						{isLoading ? <Spinner className="me-2" /> : null}
						<span>
							{step === CampaignLaunchStep.TransactionStatus
								? canSwitchWallet
									? 'Switch Wallet'
									: needWalletConnect
										? 'Connect Wallet'
										: Object.values(steps)
													?.map((i) => i.isError)
													?.some((i) => i)
											? 'Retry Process'
											: 'Start Process'
								: btnLabel}
						</span>
					</Button>
				</div>
			</DialogContent>
		</Dialog>
	);
};

export default CampaignLaunchDialog;
