import { IDepositeTokenStatus } from '@/features/campaigns/hooks/launch/useDepositTokenStatus';
import { useCampaignReport } from '@/features/campaigns/hooks/report/useCampaignReport';
import { CampaignRewardCategory, ICampaignReward } from '@/features/campaigns/types';
import { CheckCircle2, Circle, Copy, Loader2, XCircle } from 'lucide-react';
import React, { useMemo } from 'react';
import { toast } from 'sonner';

const TransactionStatus = ({
	tokenReward,
	steps,
	userBalance,
	isSpeedRunEnabled,
}: {
	tokenReward: ICampaignReward;
	steps: IDepositeTokenStatus;
	userBalance: string;
	isSpeedRunEnabled: boolean;
}) => {
	const { campaign } = useCampaignReport();

	const { rewardPool, speedRunPool, tokenSymbol } = useMemo(() => {
		const tokenRequired = campaign?.reward?.find(
			(i) => i.category === CampaignRewardCategory.Token,
		);

		const rewardPool =
			tokenRequired?.numRewards *
			+tokenRequired?.tokenReward?.tokenAmountPerUser;

		return {
			rewardPool,
			speedRunPool: campaign?.speedRunConfig?.tokenAmount,
			tokenSymbol: tokenRequired?.tokenReward?.tokenSymbol,
		};
	}, [campaign]);

	const handleCopy = (text: string, field: string) => {
		navigator.clipboard.writeText(text);
		toast.success(`${field} copied to clipboard`);
	};

	// Determine overall transaction status
	const isAllStepsCompleted = Object.values(steps).every(
		(step) => step?.isSuccess || step?.isError,
	);
	const isAllStepsSuccessful = Object.values(steps).every(
		(step) => step?.isSuccess,
	);

	return (
		<div className="w-full ">
			<div className="mb-5">
				<h3 className="text-lg font-bold mb-1 text-slate-700">
					Complete these steps to launch your campaign
				</h3>
				<p className="text-xs text-blue-600">
					Please don't close this window until all the steps are completed.
				</p>
			</div>

			<div className="space-y-4">
				<LoadingItem
					isLoading={steps.walletConnect?.isLoading}
					isCompleted={
						steps.walletConnect?.isSuccess ||
						steps.walletConnect?.isError
					}
					isSuccess={steps.walletConnect?.isSuccess}
					title="Connect your Metamask Wallet"
					errorMessage={steps.walletConnect?.error?.message}
				>
					{steps.walletConnect?.isSuccess && (
						<div className="flex items-center space-x-2 text-xs text-gray-600 truncate">
							<span>Connected Wallet:</span>
							{steps.walletConnect?.walletAddress && (
								<span className="font-mono bg-gray-100 px-2 py-1 rounded ">
									<span className="flex items-center ">
										{steps.walletConnect?.walletAddress?.slice(
											0,
											10,
										)}
										...
										{steps.walletConnect?.walletAddress?.slice(
											-10,
										)}
										<Copy
											className="cursor-pointer hover:text-blue-500 text-xs ms-2"
											size={12}
											onClick={() =>
												handleCopy(
													steps.walletConnect
														?.walletAddress || '',
													'Wallet Address',
												)
											}
										/>
									</span>
								</span>
							)}
						</div>
					)}
				</LoadingItem>

				<LoadingItem
					isLoading={steps.requiredAmount?.isLoading}
					isCompleted={
						steps.requiredAmount?.isError ||
						steps.requiredAmount?.isSuccess
					}
					isSuccess={steps.requiredAmount?.isSuccess}
					title="Check Sufficient Balance"
					errorMessage={steps.requiredAmount?.error?.message}
				>
					{steps.requiredAmount?.isSuccess && (
						<div className="text-xs text-gray-600">
							Current Balance: {userBalance} $
							{tokenReward.tokenReward?.tokenSymbol}
						</div>
					)}
				</LoadingItem>

				<LoadingItem
					isLoading={steps.approveToken?.isLoading}
					isCompleted={
						steps.approveToken?.isError || steps.approveToken?.isSuccess
					}
					isSuccess={steps.approveToken?.isSuccess}
					title="Approve Token Transfer"
					errorMessage={steps.approveToken?.error?.message}
				>
					{steps.approveToken?.isSuccess && (
						<>
							{steps.approveToken?.txHash && (
								<TransactionHashAndScanLink
									txHash={steps.approveToken?.txHash || ''}
									scanLink={steps.approveToken?.scanLink || ''}
								/>
							)}
							{!steps.approveToken?.txHash &&
								steps.approveToken.successMessage && (
									<div className="text-xs text-muted-foreground">
										{steps.approveToken.successMessage}
									</div>
								)}
						</>
					)}
				</LoadingItem>

				{isSpeedRunEnabled && (
					<LoadingItem
						isLoading={steps.transferSpeedRun?.isLoading}
						isCompleted={
							steps.transferSpeedRun?.isError ||
							steps.transferSpeedRun?.isSuccess
						}
						isSuccess={steps.transferSpeedRun?.isSuccess}
						title={`Transfer Tokens for Speedrun Booster (${speedRunPool} $${tokenSymbol}) for Quests`}
						errorMessage={steps.transferSpeedRun?.error?.message}
					>
						{steps.transferSpeedRun?.isSuccess && (
							<TransactionHashAndScanLink
								txHash={steps.transferSpeedRun?.txHash || ''}
								scanLink={steps.transferSpeedRun?.scanLink || ''}
							/>
						)}
					</LoadingItem>
				)}

				<LoadingItem
					isLoading={steps.transferToken?.isLoading}
					isCompleted={
						steps.transferToken?.isError ||
						steps.transferToken?.isSuccess
					}
					isSuccess={steps.transferToken?.isSuccess}
					title={`Transfer Reward Pool (${tokenReward?.tiersRewardPool ? Number(tokenReward?.tiersRewardPool) : Number(rewardPool)} $${tokenSymbol}) for Quests`}
					errorMessage={steps.transferToken?.error?.message}
				>
					{steps.transferToken?.isSuccess && (
						<TransactionHashAndScanLink
							txHash={steps.transferToken?.txHash || ''}
							scanLink={steps.transferToken?.scanLink || ''}
						/>
					)}
				</LoadingItem>
			</div>
		</div>
	);
};

export const LoadingItem = ({
	isLoading,
	isCompleted,
	title,
	description,
	children,
	isSuccess,
	errorMessage,
}: {
	isLoading: boolean;
	isCompleted: boolean;
	title: string;
	description?: string;
	children?: React.ReactNode;
	isSuccess: boolean;
	errorMessage?: string;
}) => {
	return (
		<div className="flex flex-col border rounded-lg bg-slate-50 px-4 py-3 space-y-2">
			<div className="flex items-center">
				{isLoading ? (
					<Loader2 className="h-5 w-5 mr-2 animate-spin text-slate-700" />
				) : isCompleted ? (
					isSuccess ? (
						<CheckCircle2 className="h-5 w-5 mr-2 text-green-500" />
					) : (
						<XCircle className="h-5 w-5 mr-2 text-red-500" />
					)
				) : (
					<Circle className="h-5 w-5 mr-2 text-gray-400" />
				)}
				<div className="flex-1">
					<div className="text-sm font-medium text-slate-700">{title}</div>
					{description && (
						<p className="text-xs text-muted-foreground">
							{description}
						</p>
					)}
				</div>
			</div>

			{/* Error message display */}
			{isCompleted && !isSuccess && errorMessage && (
				<div className="text-xs text-red-600 pl-7 mt-1">
					Error: {errorMessage}
				</div>
			)}

			{/* Completed content */}
			{isCompleted && isSuccess && children && (
				<div className="pl-7 mt-1">{children}</div>
			)}
		</div>
	);
};

export default TransactionStatus;

const TransactionHashAndScanLink = ({
	txHash,
	scanLink,
}: {
	txHash: string;
	scanLink: string;
}) => {
	const handleCopy = (text: string, field: string) => {
		navigator.clipboard.writeText(text);
		toast.success(`${field} copied to clipboard`);
	};

	return (
		<div className="flex items-center space-x-2 text-xs text-gray-600 truncate">
			<span>Transaction Hash:</span>
			{txHash && (
				<span className="font-mono bg-gray-100 px-2 py-1 rounded ">
					<span className="flex items-center ">
						{txHash?.slice(0, 10)}
						...
						{txHash?.slice(-10)}
						<Copy
							className="cursor-pointer hover:text-blue-500 text-xs ms-2"
							size={12}
							onClick={() => handleCopy(txHash || '', 'Txn Hash')}
						/>
					</span>
				</span>
			)}
			<a
				href={scanLink}
				target="_blank"
				rel="noreferrer"
				className="text-blue-500 hover:underline"
			>
				Open in Scan
				<i className="bi bi-box-arrow-up-right ms-1"></i>
			</a>
		</div>
	);
};
