import { useAuth } from '@/hooks/useAuth';
import { Button } from '@/components/ui/button';
import VerifyProjectsDialog from '../verify/VerifyProject';
import { Progress } from '@/components/ui/progress';
import EnterpriseApprovalProcess from '../verify/EnterpriseApprovalProcess';
import { useGetEnterprise } from '../../hooks/useGetEnterprise';
import {
	EnterpriseApprovalStage,
	EnterpriseApprovalStatus,
} from '../../types/enterprise.types';
import analytics from '@/lib/analytics';
import { TrackingEvents } from '@/types/tracking.type';
import { SITE_VERSION } from '@/config';
import { useCampaigns } from '@/features/campaigns/hooks/report/useCampaigns';
import { Status } from '@/features/campaigns/types';
import { useRouter } from '@/hooks/useRouter';
import { links } from '@/config/links';
import { Skeleton } from '@/components/ui/skeleton';

export const BottomNavSection = ({
	openVerifyProjects,
	setOpenVerifyProjects,
	showApprovalCompletionDialog,
	setShowApprovalCompletionDialog,
}) => {
	const { user, toggleAdminPanel, showAdminPanel } = useAuth();
	const { enterprise, isLoading } = useGetEnterprise();
	return (
		<>
			<div className="mt-auto">
				{!user?.isIntractAdmin && (
					<CompleteOnboarding
						setShowApprovalCompletionDialog={
							setShowApprovalCompletionDialog
						}
						enterprise={enterprise}
						isFetchingEnterprise={isLoading}
					/>
				)}
				{user?.isIntractAdmin && (
					<div className=" px-4 pb-4">
						<Button
							className="w-full"
							variant="outline"
							onClick={() => toggleAdminPanel()}
						>
							<i className="bi bi-gear me-2"></i>
							<span>{`${showAdminPanel ? 'Switch to Normal View' : 'Shift to Admin Panel'}`}</span>
						</Button>
					</div>
				)}
				<div className="space-y-4 p-4 border-t pb-5  text-center">
					<div className="text-xs text-muted-foreground">
						2025 Intract. Dashboard v{SITE_VERSION}
					</div>
				</div>
			</div>
			<VerifyProjectsDialog
				open={openVerifyProjects}
				setOpen={setOpenVerifyProjects}
			/>
			<EnterpriseApprovalProcess
				open={showApprovalCompletionDialog}
				setOpen={setShowApprovalCompletionDialog}
				enterpriseDetails={enterprise}
			/>
		</>
	);
};

const CompleteOnboarding = ({
	setShowApprovalCompletionDialog,
	enterprise,
	isFetchingEnterprise,
}) => {
	const { campaigns, isLoading } = useCampaigns();
	const isFirstCampaignCreated =
		enterprise?.onboardingStatus?.quest ||
		campaigns?.some((campaign) => campaign.status !== Status.Draft);

	const getCurrentStep = () => {
		if (!isFirstCampaignCreated) {
			return 0;
		}

		if (!enterprise?.approvalStage) return 1;
		return {
			[EnterpriseApprovalStage.CommunityDetails]: 1,
			[EnterpriseApprovalStage.CommunitySocials]: 1,
			[EnterpriseApprovalStage.Completed]: 2,
		}[enterprise?.approvalStage];
	};

	const { navigate } = useRouter();

	const getActionLabel = () => {
		if (!isFirstCampaignCreated) {
			return 'Create your first campaign!';
		}

		const isCompleted =
			enterprise?.approvalStage === EnterpriseApprovalStage.Completed;

		if (isCompleted) {
			return enterprise?.isApproved
				? 'Approval Completed'
				: enterprise?.approvalStatus === EnterpriseApprovalStatus.Rejected
					? 'Approval Rejected'
					: 'Approval Pending';
		}

		return 'Get your community approved';
	};

	const actionLabel = getActionLabel();
	const isApprovalCompleted = actionLabel === 'Approval Completed';
	const currentStep = getCurrentStep();

	const handleApprovalSteps = () => {
		if (!isFirstCampaignCreated) {
			navigate(links?.redirect?.createNewQuest);
			return;
		}

		if (!isApprovalCompleted) {
			analytics.track(TrackingEvents.ApprovalDialogViewed, {});
			setShowApprovalCompletionDialog(true);
		}
	};

	const isPending = isLoading || isFetchingEnterprise;

	return (
		<div
			onClick={handleApprovalSteps}
			className={`
        border block p-4 m-3 rounded-md 
        bg-white dark:bg-slate-900 relative 
        transition-all duration-200 cursor-pointer
        ${!isApprovalCompleted && 'hover:bg-zinc-100'}
      `}
		>
			{isPending ? (
				<>
					<Skeleton className="w-full h-3 " />
					<div className="mt-2 flex gap-1 items-center">
						<Skeleton className="rounded-full h-4 w-10" />
						{Array.from({ length: 2 }, (_, index) => (
							<Skeleton key={index} className="w-full h-2.5" />
						))}
					</div>
				</>
			) : (
				<>
					<div className="flex justify-between items-center">
						<div className="text-xs font-semibold">
							{actionLabel}
							{isApprovalCompleted && (
								<i className="bi bi-patch-check-fill text-green-500 ms-2" />
							)}
						</div>
						{!isApprovalCompleted && (
							<i className="bi-chevron-right text-xs cursor-pointer" />
						)}
					</div>

					<div className="mt-3 flex gap-1 items-center">
						<span className="text-xs me-2">{`${currentStep}/2`}</span>
						{Array.from({ length: 2 }, (_, index) => (
							<Progress
								key={index}
								className={
									index < currentStep ? 'bg-violet-500' : ''
								}
							/>
						))}
					</div>
				</>
			)}
		</div>
	);
};
