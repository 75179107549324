import { defineChain } from 'viem';

export const bscMainnnet = defineChain({
	id: 56,
	name: 'BNB Smart Chain',
	nativeCurrency: {
		decimals: 18,
		name: 'BNB',
		symbol: 'BNB',
	},
	rpcUrls: {
		default: {
			http: ['https://bsc.meowrpc.com'],
		},
	},
	blockExplorers: {
		default: {
			name: 'BscScan',
			url: 'https://bscscan.com',
			apiUrl: 'https://api.bscscan.com/api',
		},
	},
	testnet: false,
});
