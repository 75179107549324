import { defineChain } from 'viem';

export const haustTestnet = defineChain({
	id: 1523903251,
	name: 'Haust Testnet',
	nativeCurrency: { name: 'HAUST', symbol: 'HAUST', decimals: 18 },
	rpcUrls: {
		default: {
			http: ['https://rpc-testnet.haust.app'],
		},
	},
	blockExplorers: {
		default: {
			name: 'Haust Testnet Explorer',
			url: 'https://explorer-testnet.haust.app',
		},
	},
	testnet: true,
});
