import { API_URL } from '@/config';
import Axios from 'axios';

export const axios = Axios.create({
	baseURL: API_URL + '/api/v1/',
	withCredentials: true,
});

export const axiosForQuestServer = Axios.create({
	baseURL: API_URL + '/api/qv1/',
	withCredentials: true,
});
