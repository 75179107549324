import { defineChain } from 'viem';

export const superseed = defineChain({
	id: 5330,
	name: 'Superseed',
	nativeCurrency: { name: 'ETH', symbol: 'ETH', decimals: 18 },
	rpcUrls: {
		default: {
			http: ['https://mainnet.superseed.xyz'],
		},
	},
	blockExplorers: {
		default: {
			name: 'Superseed Explorer',
			url: 'https://explorer.superseed.xyz',
		},
	},
	testnet: false,
});
