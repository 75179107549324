import { getChainScanLink } from '@/lib/utils';

export const getScanLink = (
	txHash: string,
	chain: string,
	type: 'tx' | 'address' = 'tx',
) => {
	const blockExplorerUrl = getChainScanLink(chain);

	return `${blockExplorerUrl}/${type}/${txHash}`;
};
