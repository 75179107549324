import { defineChain } from 'viem';

export const beraChainMainnet = defineChain({
	id: 80094,
	name: 'BeraChain',
	nativeCurrency: { name: 'BERA', symbol: 'BERA', decimals: 18 },
	rpcUrls: {
		default: {
			http: ['https://rpc.berachain.com/'],
		},
	},
	blockExplorers: {
		default: {
			name: 'Berachain Mainnet Explorer',
			url: 'https://berascan.com/',
		},
	},
	testnet: false,
});
