import { cn } from "@/lib/utils";

export const JSONViewer = ({
  data,
  className,
  lineItemClassName,
}: {
  data: {
    icon?: string;
    label: string | JSX.Element;
    value: string | JSX.Element | any;
    link?: string;
  }[];
  className?: string;
  lineItemClassName?: string;
}) => {
  return (
    <div className={`mb-3 ${className}`}>
      {data &&
        Array.isArray(data) &&
        data.map((item, index) => (
          <LineItem
            key={index}
            {...item}
            lineItemClassName={lineItemClassName}
          />
        ))}
    </div>
  );
};
const LineItem = ({
  label,
  value,
  icon,
  link,
  lineItemClassName,
}: {
  icon?: string;
  label: string | JSX.Element;
  value: string | JSX.Element | any;
  link?: string;
  lineItemClassName?: string;
}) => {
  return (
    <div className={`border-b  py-2 flex items-center justify-between`}>
      {label && (
        <div className="text-sm text-slate-800 min-w-[200px]">
          {icon && <i className={`bi-${icon} me-2`}></i>}
          {label}
        </div>
      )}
      <div className={cn("text-sm font-medium", lineItemClassName)}>
        {link ? (
          <a href={link} target="_blank" className="hover:underline">
            {value}
            <i className="bi-box-arrow-up-right text-xs ms-3"></i>
          </a>
        ) : (
          value
        )}
      </div>
    </div>
  );
};
