import { CreditRequestStatusEnum } from '@/features/intract-credits/types/credits.enums';
import { axios } from '@/lib/axios';

/**
 *
 * Enterprises
 */
export const getAdminEnterprises = async (params: any) => {
	const res = await axios.get(
		`/admin/enterprises?${new URLSearchParams(params).toString()}`,
	);
	return res.data;
};

export const getAdminEnterprisesCount = async (params: any) => {
	const res = await axios.get(
		`/admin/enterprises/count?${new URLSearchParams(params).toString()}`,
	);
	return res.data;
};

export const getEnterpriseApprovalData = async (enterpriseId: string) => {
	const res = await axios.get(`/admin/enterprise/${enterpriseId}/approval`);
	return res.data;
};

export const getEnterpriseFeatureUsage = async (enterpriseId: string) => {
	const res = await axios.get(`/admin/enterprise/${enterpriseId}/feature-usage`);
	return res.data;
};

export const getSimilarEnterprises = async (enterpriseId: string) => {
	const res = await axios.get(
		`/admin/enterprise/${enterpriseId}/similar-enterprises`,
	);
	return res.data;
};

export const updateEntepriseApproval = async (enterpriseId: string, data: any) => {
	const res = await axios.put(`/admin/enterprise/${enterpriseId}/approval`, data);
	return res.data;
};

export const ghostLogin = async (enterpriseId: string) => {
	const res = await axios.put(`/admin/enterprise/${enterpriseId}/ghost-login`);
	return res.data;
};

export const removeGhostLogin = async (enterpriseId: string) => {
	new Promise((resolve) => {
		setTimeout(() => {
			resolve(true);
		}, 4000);
	});
	const res = await axios.delete(`/admin/enterprise/${enterpriseId}/ghost-login`);
	return res.data;
};

export const updateEnterpriseDetails = async (enterpriseId: string, data: any) => {
	const res = await axios.put(
		`/admin/enterprise/${enterpriseId}/update-details`,
		data,
	);
	return res.data;
};

export const updateEnterpriseTwitterHandle = async (
	enterpriseId: string,
	data: {
		enterpriseId: string;
		twitterHandle: string;
	},
) => {
	const res = await axios.post(`/admin/enterprise/twitter-handle-update`, {
		enterpriseId,
		twitterHandle: data.twitterHandle,
	});
	return res.data;
};

/**
 *
 * Campaigns
 */
export const getAdminCampaigns = async (params: any) => {
	const res = await axios.get(
		`/admin/campaigns?${new URLSearchParams(params).toString()}`,
	);
	return res.data;
};
export const getAdminCampaignsCount = async (params: any) => {
	const res = await axios.get(
		`/admin/campaigns/count?${new URLSearchParams(params).toString()}`,
	);
	return res.data;
};

export const updateCampaignPublishStatus = async (campaignId: string, data: any) => {
	const res = await axios.put(`/admin/campaigns/${campaignId}/publish`, data);
	return res.data;
};

export const updateCampaignTags = async (campaignId: string, data: any) => {
	const res = await axios.put(`/admin/campaigns/${campaignId}/tags`, data);
	return res.data;
};

export const updateCampaignEndDate = async (campaignId: string, data: any) => {
	const res = await axios.put(
		`/admin/campaigns/${campaignId}/change-end-date`,
		data,
	);
	return res.data;
};

export const fetchCampaignTasks = async (campaignId: string) => {
	const res = await axios.get(`/admin/campaigns/${campaignId}/tasks`);
	return res.data;
};

export const pauseCampaignAndTasks = async (
	campaignId: string,
	data: {
		updateType: string;
		updateData: {
			pauseReason: string;
			status: string;
			id: string;
		}[];
	},
) => {
	const res = await axios.put(
		`/admin/campaigns/${campaignId}/change-status`,
		data,
	);
	return res.data;
};

/**
 *
 * Tokens
 */
export const getAdminTokens = async (params: any) => {
	const res = await axios.get(
		`/admin/custom-tokens?${new URLSearchParams(params).toString()}`,
	);
	return res.data;
};

export const updateCustomTokenApproval = async (tokenId: string, data: any) => {
	const res = await axios.put(`/admin/custom-tokens/${tokenId}/approval`, data);
	return res.data;
};

/**
 *
 * Dave Actions
 */
export const getAdminDaveActions = async () => {
	const res = await axios.get(`/admin/dave-actions`);
	return res.data;
};

export const updateDaveActionApproval = async (actionId: string, data: any) => {
	const res = await axios.put(`/admin/dave-actions/${actionId}/approval`, data);
	return res.data;
};

/**
 * affiliate program
 */
export const getAdminAffiliateProgram = async (search: string) => {
	const res = await axios.get(`/admin/user-affiliations?search=${search}`);
	return res.data;
};

export const updateUserAffiliateRewards = async (id: string, data: any) => {
	const res = await axios.put(`/admin/update-user-affiliation/${id}`, data);
	return res.data;
};

/**
 * Events
 */

export const getAdminEvents = async (params: any) => {
	const res = await axios.get(
		`/admin/events?${new URLSearchParams(params).toString()}`,
	);
	return res.data;
};

/**
 * Credits
 */

export const adminCreditRequests = async ({
	search,
	filters,
	page,
	limit,
}: {
	search: {
		value: string;
	};
	filters: any;
	page: number;
	limit: number;
}) => {
	const res = await axios.post(`/admin/credits/requests-search`, {
		search,
		filters,
		page,
		limit,
	});
	return res.data;
};

export const updateRequests = async ({
	requestId,
	status,
	rejectionReason,
}: {
	requestId: string;
	status: CreditRequestStatusEnum;
	rejectionReason?: string;
}) => {
	const res = await axios.put(`/admin/credits/requests/${requestId}`, {
		status,
		rejectionReason,
	});
	return res.data;
};
