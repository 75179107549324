import {
  EnterpriseApprovalStatus,
  EnterpriseTokenStatus,
} from "@/features/dashboard/types/enterprise.types";
import { queryClient } from "@/lib/react-query";
import { handleErrorMessage } from "@/utils/notifications";
import { useQuery } from "@tanstack/react-query";
import { useEffect, useState } from "react";
import { toast } from "sonner";
import {
  getEnterpriseApprovalData,
  getEnterpriseFeatureUsage,
  getSimilarEnterprises,
  updateEntepriseApproval,
  updateEnterpriseDetails,
  updateEnterpriseTwitterHandle,
} from "../services/admin.service";
import {
  IAdminEnterprise,
  IAdminEnterpriseApprovalData,
  IEnterpriseFeatureUsage,
  IEnterpriseSimilarProjects,
} from "../types/admin.types";

export const useAdminEnterpriseApproval = ({ refetch, enterpriseId }) => {
  const [selected, setSelected] = useState("approvalData");
  const [isLoading, setIsLoading] = useState(false);
  const [details, setDetails] = useState<IAdminEnterpriseApprovalData>({
    status: "",
    message: "",
    reason: "",
    approveCampaigns: false,
    publishCampaigns: false,
    tokenStatus: EnterpriseTokenStatus.Unknown,
    tokenDetails: {
      tokenAddress: "",
      tokenChainId: null,
    },
    leadType: "",
    twitterCredits: 0,
    rejectionComment: "",
    twitterUsername: "",
  });
  const [errors, setErrors] = useState({
    status: false,
    message: false,
  });
  const [apiErrors, setApiErrors] = useState({
    twitterHandle: "",
    tokenDetails: "",
    general: "",
  });
  const [isTwitterHandleLoading, setIsTwitterHandleLoading] = useState(false);

  const { data: enterpriseData, isLoading: isEnterpriseLoading } =
    useQuery<IAdminEnterprise>({
      queryKey: ["admin", "enterprise", "approval", enterpriseId],
      queryFn: () => getEnterpriseApprovalData(enterpriseId),
      enabled: !!enterpriseId,
    });

  const {
    data: enterpriseFeatureUsage,
    isLoading: isEnterpriseFeatureLoading,
  } = useQuery<IEnterpriseFeatureUsage>({
    queryKey: ["admin", "enterprise", "feature", enterpriseId],
    queryFn: () => getEnterpriseFeatureUsage(enterpriseId),
    enabled: !!enterpriseId,
  });

  const { data: similarEnterprises, isLoading: isSimilarEnterpriseLoading } =
    useQuery<IEnterpriseSimilarProjects[]>({
      queryKey: ["admin", "enterprise", "similar", enterpriseId],
      queryFn: () => getSimilarEnterprises(enterpriseId),
      enabled: !!enterpriseId,
    });

  const updateStatus = async () => {
    try {
      const errors_ = {
        message: false,
        status: false,
      };
      if (!details.status) errors_.status = true;
      if (
        !details.message &&
        details.status === EnterpriseApprovalStatus.Rejected
      )
        errors_.message = true;
      setErrors(errors_);
      if (errors_.message || errors_.status) {
        return;
      }
      setIsLoading(true);
      setApiErrors({ ...apiErrors, general: "" });
      await updateEntepriseApproval(enterpriseId, {
        isApproved: details.status === EnterpriseApprovalStatus.Approved,
        notification: details.message,
        rejectionReason: details.reason,
        publishCampaigns: details.publishCampaigns,
        approveCampaigns: details.approveCampaigns,
        rejectionComment: details.rejectionComment,
      });
      toast.success("Enterprise status updated successfully");
      refetch?.();
      setIsLoading(false);
      queryClient.invalidateQueries({
        queryKey: ["admin", "enterprise"],
      });
    } catch (err) {
      handleErrorMessage(err);
      setApiErrors({
        ...apiErrors,
        general: err?.message || "Failed to update enterprise status",
      });
      setIsLoading(false);
      console.log(err);
    }
  };

  const updateInlineItems = async () => {
    try {
      setIsLoading(true);
      setApiErrors({ ...apiErrors, tokenDetails: "" });
      await updateEnterpriseDetails(enterpriseId, {
        tokenStatus: details.tokenStatus,
        leadType: details.leadType,
        tokenDetails: details.tokenDetails,
        twitterCredits: details.twitterCredits,
        twitterUsername: details.twitterUsername,
      });
      toast.success("Enterprise details updated successfully");
      refetch?.();
      queryClient.invalidateQueries({
        queryKey: ["admin", "enterprise"],
      });
    } catch (err) {
      handleErrorMessage(err);
      setApiErrors({
        ...apiErrors,
        tokenDetails:
          err?.response?.data?.message ||
          err?.message ||
          "Failed to update token details",
      });
      console.log(err);
    } finally {
      setIsLoading(false);
    }
  };
  const updateTwitterHandle = async () => {
    try {
      setIsTwitterHandleLoading(true);
      setApiErrors({ ...apiErrors, twitterHandle: "" });
      await updateEnterpriseTwitterHandle(enterpriseId, {
        twitterHandle: details.twitterUsername,
        enterpriseId: enterpriseId,
      });
      toast.success("Twitter handle updated successfully");
      refetch?.();
      queryClient.invalidateQueries({
        queryKey: ["admin", "enterprise"],
      });
    } catch (err) {
      handleErrorMessage(err);
      setApiErrors({
        ...apiErrors,
        twitterHandle:
          err?.response?.data?.message ||
          err?.message ||
          "Failed to update Twitter handle",
      });
      console.log(err);
    } finally {
      setIsTwitterHandleLoading(false);
    }
  };

  return {
    updateStatus,
    details,
    setDetails,
    selected,
    setSelected,
    isLoading,
    enterpriseData,
    isEnterpriseLoading,
    enterpriseFeatureUsage,
    isEnterpriseFeatureLoading,
    similarEnterprises,
    isSimilarEnterpriseLoading,
    errors,
    apiErrors,
    setApiErrors,
    updateInlineItems,
    updateTwitterHandle,
    isTwitterHandleLoading,
  };
};
