import InputText from '@/components/element/inputs/InputText';
import { Input } from '@/components/ui/input';
import { useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import ActionUsersTable from '../components/actions/ActionUsersTable';
import { useGetAction } from '../hooks/actions/useGetAction';
import FullPageLoading from '@/components/layout/loading/FullPageLoading';
import { DataTable } from '@/components/element/data-table/DataTable';
import { actionUsersColumns } from '../components/actions/ActionUsersColumns';
import { Button } from '@/components/ui/button';

const DaveActionPage = () => {
	const navigate = useNavigate();
	const {
		search,
		setSearch,
		action: data,
		isLoading,
		table,
		isTableLoading,
		tableCount,
		pagination,
		setPagination,
		sorting,
		setSorting,
	} = useGetAction();

	if (isLoading) {
		return <FullPageLoading />;
	}

	return (
		<div>
			<div className="flex items-center justify-between">
				<div className="space-y-1">
					<h2 className="text-2xl font-medium tracking-tight">
						{data.name}
					</h2>
					<p className="text-sm text-muted-foreground">
						{data.description}
					</p>
				</div>
				<div className="flex items-center space-x-2">
					<Button
						onClick={() =>
							navigate('/app/tools/dave/action/update/' + data._id)
						}
						variant="outline"
					>
						<i className="bi-pencil-square me-2"></i>
						Edit Action
					</Button>
					<Button
						onClick={() =>
							navigate(
								'/app/tools/dave/query/new?actionId=' + data._id,
							)
						}
					>
						<i className="bi-ui-checks me-2"></i>
						Create Query
					</Button>
				</div>
			</div>
			<div className="mt-10">
				<div className="flex justify-between my-4">
					<InputText
						placeholder="Search user addresses"
						className="w-1/4"
						prepend={<i className="bi-search mx-3"></i>}
						value={search}
						setValue={(e) => setSearch(e)}
					/>
				</div>
				<DataTable
					data={table ?? []}
					isLoading={isTableLoading}
					totalCount={tableCount ?? 10}
					columns={actionUsersColumns(data.chainId, data.chain)}
					isServerSide
					pagination={pagination}
					setPagination={setPagination}
					sorting={sorting}
					setSorting={setSorting}
				/>
			</div>
		</div>
	);
};

export default DaveActionPage;
