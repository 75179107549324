import { Badge } from '@/components/ui/badge';
import boosterImg from '../../../assets/rewards/booster.png';
import { useCampaignReport } from '@/features/campaigns/hooks/report/useCampaignReport';
import { cn } from '@/lib/utils';
import TooltipWrapper from '@/components/element/tooltips/TooltipWrapper';
import analytics from '@/lib/analytics';
import { TrackingEvents } from '@/types/tracking.type';
import { useAuth } from '@/hooks/useAuth';
import { useNavigate } from 'react-router-dom';
import { links } from '@/config/links';

const IntroducingBoostersContent = ({ setStep }) => {
	const { campaign } = useCampaignReport();
	const { user } = useAuth();
	const navigate = useNavigate();
	return (
		<div>
			<div
				className="p-4 flex items-center justify-center"
				style={{
					background:
						'linear-gradient(147.15deg, rgb(65, 10, 182) 30.17%, rgb(212, 92, 255) 100.01%)',
				}}
			>
				<img src={boosterImg} className="h-48 -my-4" />
			</div>
			<div className="flex flex-col items-center justify-center w-full  p-5">
				<div className="text-xl mb-1 font-medium">
					Introducing Quest Boosters
				</div>
				<div className="text-center text-sm text-muted-foreground px-10">
					Boosters are a new way to increase your quest visibility and
					reach.
				</div>
				<div className="px-@ w-full mt-4">
					{!campaign.alphaHubNarrativeId && (
						<BoosterItem
							iconColor={{
								text: 'text-green-800',
								bg: 'bg-green-50',
							}}
							icon="speedometer"
							title="Speed Run"
							description="Boost engagement by combining time-bound quests with gamified rewards"
							onClick={() => {
								setStep('speedrun.introduction');
							}}
							disabled={
								!!campaign?.speedRunConfig?.transaction?.txHash
							}
							isHot
						/>
					)}
					<BoosterItem
						iconColor={{
							text: 'text-red-800',
							bg: 'bg-red-50',
						}}
						icon="houses"
						title="Quest Placement on Homepage"
						description="Use your credits to boost your quest on our homepage"
						onClick={() => {
							navigate('/app/credits');
							analytics.track(TrackingEvents.BoostCampaignClicked, {
								userId: user?._id,
								enterpriseId: user.enterpriseId,
								campaignId: campaign?._id,
							});
						}}
						isHot={false}
					/>
					<BoosterItem
						iconColor={{
							text: 'text-yellow-600',
							bg: 'bg-yellow-50',
						}}
						icon="star"
						title="Challenger Series"
						description="One Click on-chain tasks for genuine product engagement"
						onClick={() => {
							window.open(links.challengerSeriesForm, '_blank');
						}}
						isHot={false}
					/>
				</div>
			</div>
		</div>
	);
};

export default IntroducingBoostersContent;

const BoosterItem = ({
	iconColor,
	title,
	description,
	icon,
	onClick,
	isHot,
	disabled,
}: {
	iconColor: { text: string; bg: string };
	title: string;
	description: string;
	icon: string;
	onClick?: () => void;
	isHot: boolean;
	disabled?: boolean;
}) => {
	return (
		<div
			className={cn(
				`flex items-center relative border justify-between mt-5 group w-full  bg-slate-50 p-2 rounded `,
				disabled
					? 'cursor-not-allowed'
					: 'hover:scale-105 transition-all cursor-pointer',
			)}
			onClick={() => {
				if (disabled) return false;
				onClick?.();
			}}
		>
			<div className="absolute -top-3 end-0">
				{isHot && !disabled && (
					<Badge variant="destructive" className="me-4">
						Recommended for quests without onchain tasks
					</Badge>
				)}
			</div>
			<div className="flex items-center ">
				<div
					className={`h-10 w-10 rounded-lg ${iconColor?.bg} flex items-center justify-center me-3`}
				>
					<i
						className={`bi bi-${icon} ${iconColor?.text} text-muted-foreground text-xl`}
					></i>
				</div>
				<div>
					<div className="flex justify-between items-end">
						<div className="text-sm font-medium">{title} </div>
					</div>
					<div className="text-xs text-muted-foreground">
						{description}
					</div>
				</div>
			</div>
			<div className="flex items-center">
				{disabled && (
					<TooltipWrapper tooltip="Already enrolled">
						<div className="h-8 w-8 rounded-lg  flex items-center justify-center border group-hover:bg-white me-3">
							<i className="bi bi-check2"></i>
						</div>
					</TooltipWrapper>
				)}
				<div className="h-8 w-8 rounded-lg  flex items-center justify-center border group-hover:bg-white">
					<i className="bi bi-arrow-right"></i>
				</div>
			</div>
		</div>
	);
};
