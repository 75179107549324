import InputSelect from "@/components/element/inputs/InputSelect";
import InputText from "@/components/element/inputs/InputText";
import { Button } from "@/components/ui/button";
import { EnterpriseTokenStatus } from "@/features/dashboard/types/enterprise.types";
import { tokenStatus } from "@/features/onboarding/types/onboarding-options";
import { stripURLs } from "@/utils/parsers";
import { Dispatch, SetStateAction } from "react";
import AnimateHeight from "react-animate-height";
import { IAdminEnterpriseApprovalData } from "../../types/admin.types";
import { Loader2 } from "lucide-react";

const EditEnterpriseData = ({
  isDataEditable,
  details,
  setDetails,
  updateInlineItems,
  updateTwitterHandle,
  enterpriseId,
  isTwitterHandleLoading,
  apiErrors,
}: {
  isDataEditable: string;
  details: IAdminEnterpriseApprovalData;
  setDetails: Dispatch<SetStateAction<IAdminEnterpriseApprovalData>>;
  updateInlineItems: () => void;
  updateTwitterHandle?: (enterpriseId: string, twitterHandle: string) => void;
  enterpriseId: string;
  isTwitterHandleLoading?: boolean;
  apiErrors?: {
    twitterHandle: string;
    tokenDetails: string;
    general: string;
  };
}) => {
  return (
    <div className="w-full">
      <AnimateHeight
        height={isDataEditable === "tokenStatus" ? "auto" : 0}
        duration={300}
        className="my-2 w-full"
      >
        <div className="space-y-4 w-full">
          <InputSelect
            label="What is your token status?"
            placeholder="Select token status"
            options={tokenStatus}
            value={details.tokenStatus}
            setValue={(e) =>
              setDetails((p: IAdminEnterpriseApprovalData) => ({
                ...p,
                tokenStatus: e as EnterpriseTokenStatus,
              }))
            }
            errorText="Please select a category"
            className="text-left w-full"
            placeholderClassName="!text-muted-foreground"
          />
          {details.tokenStatus === EnterpriseTokenStatus.Listed ? (
            <div className="flex gap-4 w-full">
              <InputText
                label="Chain Id"
                placeholder="1234"
                value={details?.tokenDetails?.tokenChainId}
                setValue={(e) => {
                  const numericValue = Number(e);
                  if (Number.isNaN(numericValue)) {
                    // If the value is not a number, return early
                    return;
                  }
                  setDetails((p) => ({
                    ...p,
                    tokenDetails: {
                      ...p.tokenDetails,
                      tokenChainId: numericValue,
                    },
                  }));
                }}
                className="text-left w-[20%]"
              />
              <InputText
                label="Token Address"
                placeholder="0xb851...aaca9"
                value={details?.tokenDetails?.tokenAddress}
                setValue={(e) =>
                  setDetails((p) => ({
                    ...p,
                    tokenDetails: {
                      ...p.tokenDetails,
                      tokenAddress: e,
                    },
                  }))
                }
                className="text-left w-[80%]"
              />
            </div>
          ) : null}
          {apiErrors?.tokenDetails && (
            <div className="text-red-400 text-xs flex justify-end">
              {apiErrors.tokenDetails}
            </div>
          )}
        </div>
      </AnimateHeight>

      <AnimateHeight
        height={isDataEditable === "twitterHandle" ? "auto" : 0}
        duration={300}
        className="my-2 w-full"
      >
        <div className="flex flex-col w-full">
          <InputText
            label="Enter the new official Twitter profile URL"
            placeholder="username"
            value={details.twitterUsername || ""}
            setValue={(e) => {
              setDetails((p) => ({
                ...p,
                twitterUsername: e,
              }));
            }}
            prepend={
              <div className="ps-3 pe-2 flex items-center text-xs">
                <i className="bi bi-twitter text-twitter pe-2"></i>
                <span className="text-muted-foreground font-medium">
                  https://twitter.com/
                </span>
              </div>
            }
            className={`text-left !w-full ${
              apiErrors?.twitterHandle ? "border-red-400" : ""
            }`}
            errorText={apiErrors?.twitterHandle}
            error={apiErrors?.twitterHandle}
            wrapperClassName="!w-full"
          />

          <div className="w-full mt-4">
            <Button
              variant="secondary"
              className="text-sm w-full"
              onClick={() => {
                if (details.twitterUsername) {
                  const strippedURL = stripURLs({
                    twitter: details.twitterUsername,
                  });

                  setDetails((p) => ({
                    ...p,
                    twitterUsername: strippedURL.twitter,
                  }));

                  updateTwitterHandle &&
                    updateTwitterHandle(enterpriseId, details.twitterUsername);
                }
              }}
              disabled={isTwitterHandleLoading}
            >
              <span className="flex items-center gap-2">
                {isTwitterHandleLoading ? (
                  <>
                    <Loader2 className="w-4 h-4 animate-spin" />
                    Saving...
                  </>
                ) : (
                  "Save Twitter"
                )}
              </span>
            </Button>
          </div>
        </div>
      </AnimateHeight>
    </div>
  );
};

export default EditEnterpriseData;
