import { useQuery } from '@tanstack/react-query';
import { getTaskTemplates } from '../../services/campaigns.service';
import { useMemo } from 'react';
import {
	AdminInputType,
	ITaskAdminInput,
	ITaskTemplates,
	TaskKeys,
	TemplateFamily,
	TemplateType,
} from '../../types';
import { useAuth } from '@/hooks/useAuth';

export const useTaskTemplates = () => {
	const { user } = useAuth();

	const { data, isLoading } = useQuery<ITaskTemplates[]>({
		queryKey: ['task-templates'],
		queryFn: () => getTaskTemplates(),
	});

	const families = useMemo(
		() => [
			{
				label: 'Most Frequently Used',
				value: TemplateFamily.MostFrequent,
				icon: 'star-fill',
				color: 'text-amber-500',
			},
			{
				label: 'Discord Tasks',
				value: TemplateFamily.Discord,
				icon: 'discord',
				color: 'text-blue-500',
			},
			{
				label: 'Twitter Tasks',
				value: TemplateFamily.Twitter,
				icon: 'twitter',
				color: 'text-blue-400',
			},
			{
				label: 'Github',
				value: TemplateFamily.GitHub,
				icon: 'github',
				color: 'text-white-500',
			},
			{
				label: 'Form Based Tasks',
				value: TemplateFamily.Forms,
				icon: 'ui-checks',
				color: 'text-red-500',
			},
			{
				label: 'On Chain Tasks',
				value: TemplateFamily.Wallet,
				icon: 'wallet-fill',
				color: 'text-violet-500',
			},
			{
				label: 'Email Based Tasks',
				value: TemplateFamily.Email,
				icon: 'envelope-fill',
				color: 'text-green-500',
			},
			{
				label: 'Telegram Tasks',
				value: TemplateFamily.Telegram,
				icon: 'telegram',
				color: 'text-blue-500',
			},
			{
				label: 'POH Tasks',
				value: TemplateFamily.POH,
				icon: 'person-bounding-box',
				color: 'text-yellow-600',
			},
			{
				label: 'Others',
				value: TemplateFamily.Others,
				icon: 'columns',
				color: 'text-purple-500',
			},
			{
				label: 'Custom Verification',
				value: TemplateFamily.Custom,
				icon: 'patch-check-fill',
				color: 'text-emerald-600',
			},
		],
		[],
	);

	const getColSize = (
		adminInput: ITaskAdminInput,
		_templateType: TemplateType,
		templateFamily: TemplateFamily,
	) => {
		if (templateFamily === TemplateFamily.Forms) return 'col-span-12';
		if (templateFamily === TemplateFamily.Twitter) return 'col-span-12';
		if (adminInput.key === TaskKeys.DiscordInviteLink) return 'col-span-12';
		if (adminInput.key === TaskKeys.QueryId) return 'col-span-12';
		if (adminInput.key === TaskKeys.TaskTitle) return 'col-span-12';
		if (adminInput.key === TaskKeys.Question) return 'col-span-12';
		if (adminInput.key === TaskKeys.Answer) return 'col-span-12';
		if (adminInput.inputType === AdminInputType.InputCheckbox)
			return 'col-span-12';
		if (adminInput.inputType === AdminInputType.InputDate) return 'col-span-12';
		if (adminInput.inputType === AdminInputType.InputStringArray)
			return 'col-span-12';
		else return 'col-span-6';
	};

	const getDefaultValue = (
		adminInput: ITaskAdminInput,
		_templateType: TemplateType,
		_templateFamily: TemplateFamily,
	) => {
		if (adminInput.key === TaskKeys.IsTaskTitle) return true;
		if (adminInput.inputType === AdminInputType.TwitterTags) return 0;
		if (adminInput.inputType === AdminInputType.InputStringArray)
			return [
				{ id: '1', value: '' },
				{ id: '2', value: '' },
			];
		if (adminInput.inputType === AdminInputType.InputCheckbox) {
			return (
				adminInput.key === TaskKeys.TwitterAcknowledgementOpt &&
				(adminInput.value === 'true' || adminInput.value === true)
			);
		}
		return '';
	};
	const checkIfHide = (
		adminInput: ITaskAdminInput,
		_templateType: TemplateType,
		_templateFamily: TemplateFamily,
	) => {
		if (adminInput.key === TaskKeys.IsTaskTitle) return true;
		else return adminInput.hide || false;
	};

	const templates: {
		mostFrequent: boolean;
		filteredTemplates: ITaskTemplates[];
		group: string;
		icon: string;
		family: TemplateFamily;
		color: string;
		templates: ITaskTemplates[];
	}[] = useMemo(() => {
		if (!data) return [];

		const templates = data.reduce((acc: any, i: ITaskTemplates) => {
			const adminInputs = i.adminInputs.map((adminInput) => ({
				...adminInput,
				size:
					i.adminInputs.length === 1
						? `col-span-12`
						: getColSize(adminInput, i.templateType, i.templateFamily),
				value: getDefaultValue(adminInput, i.templateType, i.templateFamily),
				hide: checkIfHide(adminInput, i.templateType, i.templateFamily),
			}));
			i.adminInputs = adminInputs;
			const family = families.find((f) => f.value === i.templateFamily);
			if (!family) return acc;

			i.logo = i.logo
				? i.logo
				: {
						value: `bi-${family.icon}`,
						type: 'icon',
					};
			i.color = family.color;

			i.disabled =
				i.integrationRequired?.includes('TELEGRAM') &&
				!user.integrationStatus.telegram;

			i.mostFrequent = i.mostFrequent || false;

			const index = acc.findIndex((i: any) => i.group === family.label);
			if (index === -1) {
				acc.push({
					group: family.label,
					icon: family.icon,
					family: family.value,
					color: family.color,
					templates: [i],
				});
			} else {
				acc[index].templates.push(i);
			}
			return acc;
		}, []);
		return templates;
	}, [data, families]);

	const getDefaultLogo = (family: TemplateFamily) => {
		const family_ = families.find((f) => f.value === family);
		if (!family_)
			return {
				value: 'bi-columns',
				color: 'text-gray-500',
			};
		return {
			value: `bi-${family_.icon}`,
			color: family_.color,
		};
	};

	const rawTemplates = useMemo(
		() =>
			(templates?.reduce(
				(acc, i) => [...acc, ...i.templates],
				[],
			) as ITaskTemplates[]) ?? ([] as ITaskTemplates[]),
		[templates],
	);

	return {
		templates,
		rawTemplates: rawTemplates as ITaskTemplates[],
		isLoading,
		families,
		getDefaultLogo,
	};
};
