import Spinner from "@/components/element/loading/Spinner";
import TabOptions from "@/components/element/tabs/TabOptions";
import { Button } from "@/components/ui/button";
import { DialogTitle } from "@/components/ui/dialog";
import { Sheet, SheetContent } from "@/components/ui/sheet";
import { QUEST_URL } from "@/config";
import { EnterpriseTokenStatus } from "@/features/dashboard/types/enterprise.types";
import { useEffect } from "react";
import { useAdminEnterpriseApproval } from "../../hooks/useAdminEnterpriseApproval";
import {
  IAdminEnterpriseApprovalData,
  IAdminEnterprises,
} from "../../types/admin.types";
import EnterpriseApproval from "./EnterpriseApproval";
import {
  ApprovalData,
  EnterpriseCampaigns,
  EnterpriseFeatureUsage,
  SimilarEnterprises,
} from "./EnterpriseDetails";

const EnterpriseApprovalDialog = ({
  open,
  setOpen,
  enterprise,
  refetch,
}: {
  open: boolean;
  setOpen: any;
  refetch: any;
  enterprise: IAdminEnterprises;
}) => {
  const {
    updateStatus,
    setDetails,
    selected,
    errors,
    setSelected,
    isLoading,
    details,
    enterpriseData,
    isEnterpriseLoading,
    enterpriseFeatureUsage,
    isEnterpriseFeatureLoading,
    similarEnterprises,
    isSimilarEnterpriseLoading,
    updateInlineItems,
    updateTwitterHandle,
    isTwitterHandleLoading,
    apiErrors,
    setApiErrors,
  } = useAdminEnterpriseApproval({
    enterpriseId: enterprise?._id,
    refetch: refetch,
  });

  const mergedEnterpriseData = {
    approvalData: { ...enterpriseData?.approvalData },
    enterpriseData: {
      ...enterpriseData?.enterpriseData,
      ...enterprise,
      socials: {
        ...enterpriseData?.enterpriseData.socials,
        twitter: "", // Add the 'twitter' property with an empty string value
      },
    },
    campaigns: enterpriseData?.campaigns,
  };

  useEffect(() => {
    if (enterpriseData) {
      setDetails((prev: IAdminEnterpriseApprovalData) => ({
        ...prev,
        tokenStatus: enterprise?.tokenStatus as EnterpriseTokenStatus,
        tokenDetails: enterprise?.tokenDetails,
        leadType: enterprise?.leadType,
        twitterCredits: enterprise?.twitterCredits,
        twitterUrl: enterpriseData?.approvalData?.twitter?.username
          ? `https://twitter.com/${enterpriseData?.approvalData?.twitter?.username}`
          : "",
      }));
    }
  }, [enterpriseData, enterprise, setDetails]);

  useEffect(() => {
    setApiErrors({
      twitterHandle: "",
      tokenDetails: "",
      general: "",
    });
    setDetails((prev: IAdminEnterpriseApprovalData) => ({
      ...prev,
      twitterUsername: "",
    }));
  }, [open, setApiErrors, setDetails]);

  return (
    <Sheet open={open} onOpenChange={setOpen}>
      <SheetContent className="min-w-[550px] justify-between flex flex-col p-4 h-full ">
        <div className="h-full">
          <div className="border-b pb-3">
            <div
              onClick={() => {
                window.open(
                  QUEST_URL +
                    "/project/" +
                    enterpriseData?.enterpriseData?.slug,
                  "_blank"
                );
              }}
            >
              <DialogTitle>Update Enterprise Approval Status</DialogTitle>
              <div className="hover:underline mt-3 cursor-pointer text-muted-foreground text-sm flex items-center">
                <i className="bi bi-box-arrow-up-right me-2"></i>
                Community Hub Link
              </div>
            </div>
          </div>
          <TabOptions
            options={[
              { label: "Approval Data", value: "approvalData" },
              { label: "Campaigns", value: "campaigns" },
              { label: "Feature Usage", value: "featureUsage" },
              {
                label: "Similar Projects",
                value: "similarEnterprises",
              },
            ]}
            selected={selected}
            setSelected={setSelected}
            className="mt-6"
          />
          <div className="mt-10 mb-2 overflow-auto max-h-[68dvh]">
            {selected === "approvalData" && (
              <ApprovalData
                enterprise={mergedEnterpriseData}
                isLoading={isEnterpriseLoading}
                isTwitterHandleLoading={isTwitterHandleLoading}
                details={details}
                setDetails={setDetails}
                updateInlineItems={updateInlineItems}
                updateTwitterHandle={updateTwitterHandle}
                apiErrors={apiErrors}
              />
            )}
            {selected === "campaigns" && (
              <EnterpriseCampaigns
                enterprise={enterpriseData}
                isLoading={isEnterpriseLoading}
              />
            )}
            {selected === "featureUsage" && (
              <EnterpriseFeatureUsage
                enterpriseFeatureUsage={enterpriseFeatureUsage}
                isLoading={isEnterpriseFeatureLoading}
              />
            )}
            {selected === "similarEnterprises" && (
              <SimilarEnterprises
                similarEnterprises={similarEnterprises}
                isLoading={isSimilarEnterpriseLoading}
              />
            )}
            {selected === "approval" && (
              <EnterpriseApproval
                details={details}
                setDetails={setDetails}
                errors={errors}
                apiErrors={apiErrors}
              />
            )}
            {apiErrors?.general && selected === "approval" && (
              <div className="text-red-500 text-sm mt-3">
                {apiErrors.general}
              </div>
            )}
          </div>
        </div>
        <div
          className={`justify-start space-x-2 border-t pt-3 flex sm:justify-between mt-auto pe-20 `}
        >
          <div className="flex items-center gap-2">
            {selected !== "approval" && (
              <Button
                onClick={() => setSelected("approval")}
                disabled={isLoading}
              >
                Update Approval Status
              </Button>
            )}
            {selected === "approval" && (
              <Button onClick={() => updateStatus()} disabled={isLoading}>
                {isLoading && <Spinner className="me-2" />}
                Save Approval Status
              </Button>
            )}
            <Button variant="ghost" onClick={() => setOpen(false)}>
              Cancel
            </Button>
          </div>
        </div>
      </SheetContent>
    </Sheet>
  );
};

export default EnterpriseApprovalDialog;
